import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Callout from '../components/callout'
import { Link } from "gatsby"

import NotFound from '../images/404.svg'



const NotFoundPage = () => (
  <Layout>
    <SEO title="¯\_(ツ)_/¯" />
    
      <section className="NotFound card">

        <img src={NotFound} className ="NotFoundIllustration" alt="404 Illustration"/>

    <h1><svg width="48" className ="icon "height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M-4.0055e-06 24C-2.84674e-06 10.7453 10.7453 -3.25691e-06 24 -2.09815e-06C37.2547 -9.39387e-07 48 10.7453 48 24C48 37.2547 37.2547 48 24 48C10.7453 48 -5.16426e-06 37.2547 -4.0055e-06 24Z" fill="#FFDD67"/>
<path d="M12.2083 17.8376C12.4332 18.041 12.7692 18.0536 13.0104 17.8743C13.0597 17.8376 17.9645 14.2111 23.9999 14.2111C30.0201 14.2111 34.9414 17.8376 34.9894 17.8743C35.2306 18.0536 35.5666 18.0385 35.7915 17.8376C36.0151 17.6355 36.0656 17.3021 35.9102 17.0444C35.7473 16.7728 31.8466 10.4216 23.9999 10.4216C16.1532 10.4216 12.2513 16.7728 12.0896 17.0444C11.9342 17.3033 11.9835 17.6355 12.2083 17.8376Z" fill="#664500"/>
<path d="M30.3428 26.4001C30.3428 23.7478 31.8774 21.6001 33.7714 21.6001C35.6653 21.6001 37.2 23.7478 37.2 26.4001C37.2 29.0511 35.6653 31.2001 33.7714 31.2001C31.8774 31.2001 30.3428 29.0511 30.3428 26.4001Z" fill="#664500"/>
<path d="M12.5135 26.4001C12.5135 23.7478 14.0481 21.6001 15.942 21.6001C17.836 21.6001 19.3706 23.7478 19.3706 26.4001C19.3706 29.0511 17.836 31.2001 15.942 31.2001C14.0481 31.2001 12.5135 29.0511 12.5135 26.4001Z" fill="#664500"/>
</svg>Oops!<svg width="48" className ="icon "height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M-4.0055e-06 24C-2.84674e-06 10.7453 10.7453 -3.25691e-06 24 -2.09815e-06C37.2547 -9.39387e-07 48 10.7453 48 24C48 37.2547 37.2547 48 24 48C10.7453 48 -5.16426e-06 37.2547 -4.0055e-06 24Z" fill="#FFDD67"/>
<path d="M12.2083 17.8376C12.4332 18.041 12.7692 18.0536 13.0104 17.8743C13.0597 17.8376 17.9645 14.2111 23.9999 14.2111C30.0201 14.2111 34.9414 17.8376 34.9894 17.8743C35.2306 18.0536 35.5666 18.0385 35.7915 17.8376C36.0151 17.6355 36.0656 17.3021 35.9102 17.0444C35.7473 16.7728 31.8466 10.4216 23.9999 10.4216C16.1532 10.4216 12.2513 16.7728 12.0896 17.0444C11.9342 17.3033 11.9835 17.6355 12.2083 17.8376Z" fill="#664500"/>
<path d="M30.3428 26.4001C30.3428 23.7478 31.8774 21.6001 33.7714 21.6001C35.6653 21.6001 37.2 23.7478 37.2 26.4001C37.2 29.0511 35.6653 31.2001 33.7714 31.2001C31.8774 31.2001 30.3428 29.0511 30.3428 26.4001Z" fill="#664500"/>
<path d="M12.5135 26.4001C12.5135 23.7478 14.0481 21.6001 15.942 21.6001C17.836 21.6001 19.3706 23.7478 19.3706 26.4001C19.3706 29.0511 17.836 31.2001 15.942 31.2001C14.0481 31.2001 12.5135 29.0511 12.5135 26.4001Z" fill="#664500"/>
</svg></h1>
    <p id="NotFoundContent">Seems like the page you are looking for doesn’t exist, but while you’re here <span role="img" aria-label="finger pointing down">👇</span></p>

      <Callout data={["Join our ",<a href='https://teamsoundbite.slack.com/join/shared_invite/enQtNzYyNzAxNTc0NzM3LWMyNWQxMjQ2ZDliNWQzNDI1MzU1NjIxMWQyODU5ZTE3NDZhOGMzNGQ3NDAzOTViZTY2ZDg3NTgwMGM3Nzg3NjE'>Slack Community</a>, " to tell us what’s on your mind & what you’d like to see" ]} />

      <Link id="buttonLink" to="/"><button type="button">Or go home</button></Link>
    
      </section>

    

    


  </Layout>
)

export default NotFoundPage
